import React from 'react';
import { Link } from 'gatsby';
import Artwork2 from '~/images/Artwork2.svg';

const BrandingBar = () => (
  <div className="px-12 text-right">
    <Link to="/">
      <div className="py-4 inline-flex items-baseline">
        <h2>Endurabl</h2>
        <img
          src={Artwork2}
          alt="Artwork"
          css={{ width: '2rem', height: '2rem' }}
          className="ml-4"
        />
      </div>
    </Link>
  </div>
);

export default BrandingBar;
