import React from 'react';
import { Router } from '@reach/router';
import Layout from '~/components/layout';
import Container from '~/components/container';
import BrandingBar from '~/components/brandingBar';
import ProfileList from '~/components/profileList';
import Profile from '~/components/profile';
import useCoachData from '~/hooks/useCoachData';
import SEO from '~/components/seo';

const App = () => {
  const { profiles } = useCoachData();
  profiles.sort((first, second) =>
    first.name.toUpperCase() < second.name.toUpperCase() ? -1 : 1
  );

  return (
    <Layout>
      <SEO title="Coaches" />
      <BrandingBar />
      <Container className="mt-8">
        <Router basepath="/profiles">
          <ProfileList path="/" profiles={profiles} />
          <Profile path="/:slug" profiles={profiles} />
        </Router>
      </Container>
    </Layout>
  );
};

export default App;
