import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import ReactPlayer from 'react-player';
import prependHttp from 'prepend-http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitterSquare,
  faLinkedin,
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import tw from 'twin.macro';

const formatText = (text) =>
  text.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));

const Certification = styled.li`
  ${tw`bg-x-mediumgray inline-block px-2 py-1 text-gray-200 text-xs uppercase tracking-widest font-semibold`}
  font-family: "mr-eaves-sans", sans-serif;
  ${tw`mb-2`}
  &:not(:last-child) {
    ${tw`mr-2`}
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  ${tw`bg-x-mediumgray`}
`;

const Profile = ({ slug, profiles }) => {
  // TODO: get data the correct way (don't search through a list every time...)
  const profileIndex = profiles.findIndex((element) => element.slug === slug);
  const prevIndex =
    profileIndex - 1 < 0 ? profiles.length - 1 : profileIndex - 1;
  const nextIndex = profileIndex + 1 === profiles.length ? 0 : profileIndex + 1;

  const profile = profiles[profileIndex];
  const prevProfile = profiles[prevIndex];
  const nextProfile = profiles[nextIndex];
  const website = profile.website ? prependHttp(profile.website) : null;

  return (
    <div className="mt-8">
      <div className="flex flex-col laptop-up:flex-row">
        <div className="flex-1">
          <div className="h-12 flex justify-end items-center">
            <Link to={`../${prevProfile.slug}`}>
              <div className="inline-flex items-center px-2 py-2">
                <div>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className="ml-2 eaves">Prev</div>
              </div>
            </Link>
            <Link to={`../${nextProfile.slug}`} className="ml-12">
              <div className="inline-flex items-center px-2 py-2">
                <div className="mr-2 eaves">Next</div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </Link>
          </div>
          <div className="bg-x-pink px-8 py-4">
            <a href={website} target="_blank" rel="noopener noreferrer">
              <h2 className="">{profile.name}</h2>
            </a>
            {profile.certifications && (
              <ul className="mt-4 flex flex-row flex-wrap">
                {profile.certifications.map((cert, index) => (
                  <Certification key={index}>{cert.name}</Certification>
                ))}
              </ul>
            )}
            <div className="mt-8">{profile.bio}</div>
            {profile.workwithme && (
              <div className="mt-12">
                <h3 className="font-semibold">Work with me:</h3>
                <ul className="list-disc">
                  {profile.workwithme.map((item, index) => (
                    <li key={index} className="py-4">
                      {formatText(item.worktype)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 laptop-up:ml-4">
          <div className="h-12" />
          {profile.video && (
            <VideoWrapper>
              <ReactPlayer
                url={profile.video}
                className="absolute top-0 left-0 w-full h-full"
                width="100%"
                height="100%"
              />
            </VideoWrapper>
          )}
          <div className="mt-4">
            <ul className="flex justify-end text-x-darkred text-3xl">
              {profile.twitter && (
                <li className="ml-4">
                  <a
                    href={profile.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitterSquare} />
                  </a>
                </li>
              )}
              {profile.linkedin && (
                <li className="ml-4">
                  <a
                    href={profile.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              )}
              {profile.instagram && (
                <li className="ml-4">
                  <a
                    href={profile.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagramSquare} />
                  </a>
                </li>
              )}
              {profile.facebook && (
                <li className="ml-4">
                  <a
                    href={profile.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="mt-12 flex flex-col items-center">
            <div className="mx-auto">
              <a
                href={profile.intake}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="inline-block bg-x-teal px-8 py-2 text-center eaves text-white text-2xl font-normal hover:bg-x-darkblue">
                  I'm interested in working with <br /> {profile.name}
                </div>
              </a>
              <a
                href="https://airtable.com/shrd42hx9EvA2RSF6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="mt-4 text-sm underline">
                  Not interested in one of our pilot coaches? Tell us why!
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  slug: PropTypes.string,
  profiles: PropTypes.array,
};
export default Profile;
