import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

const ProfileList = ({ profiles }) => (
  <div className="bg-x-pink px-8 py-12">
    {profiles.map((profile, index) => (
      <div key={index} className="py-2">
        <Link to={profile.slug} className="underline">
          {profile.name}
        </Link>
      </div>
    ))}
  </div>
);

ProfileList.propTypes = {
  profiles: PropTypes.array,
};
export default ProfileList;
